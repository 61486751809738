<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="강사용 교재 상세"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>요청자</th>
                                <td>{{ view.info.staff_ename }}({{ view.info.staff_kname }})
                                </td>
                                <th>등록일</th>
                                <td>{{ view.info.wdate }}</td>
                            </tr>
                            <tr>
                                <th>출고현황</th>
                                <td>
                                    <div v-if="view.info.state=='REQUEST'" class="float-left">요청</div>
                                    <div v-if="view.info.state=='DONE'" class="float-left">완료 {{ view.info.udate }}</div>
                                    <div v-if="view.info.state=='CANCEL'" class="float-left">취소 {{ view.info.cancel_date }}</div>
                                </td>
                                <th>처리자</th>
                                <td>{{ view.info.mgr_ename }}({{ view.info.mgr_kname }})</td>
                            </tr>
                            <tr>
                                <th>내/외부교재</th>
                                <td colspan="3">{{ view.info.btype_txt }}교재</td>
                            </tr>
                            <tr>
                                <th>교재명</th>
                                <td colspan="3">{{ view.info.books[0].bookname }}</td>
                            </tr>
                            <tr>
                                <th>소속/강사명</th>
                                <td colspan="3">{{ view.info.tutorname }} ({{ view.info.tutortype }})</td>
                            </tr>
                            <tr>
                                <th>클래스</th>
                                <td colspan="3">{{ view.info.title }}</td>
                            </tr>
                            <tr>
                                <th>출고희망일</th>
                                <td colspan="3">{{ view.info.request_date }}</td>
                            </tr>
                            <tr>
                                <th>금액</th>
                                <td colspan="3">
                                    10,000원
                                </td>
                            </tr>
                            <tr>
                                <th>비고<br>(세부내용)</th>
                                <td colspan="3" class="preline">{{ view.info.etc_memo }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <button @click="view.goList" class="btn-default float-left mt-30">목록</button>
                    <button v-if="view.info.is_owner=='Y'&&view.info.state=='REQUEST'" @click="view.doDelete" class="btn-default float-left mt-30 ml-10">삭제</button>
                    <button v-if="view.info.is_owner=='Y'" @click="view.showModify" class="btn-default float-right mt-30">수정</button>
                    <button v-if="['A','Y'].indexOf(view.info.is_owner)>=0&&(view.info.state=='CANCEL'||view.info.state=='REQUEST')" @click="view.doDone" class="btn-default float-right mt-30 mr-20">출고 완료</button>
                    <button v-if="['A','Y'].indexOf(view.info.is_owner)>=0&&(view.info.state=='DONE'||view.info.state=='REQUEST')" @click="view.doCancel" class="btn-default float-right mt-30 mr-20">출고 취소</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'


export default {
    layout:"ResourceManagement",
    components: {
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast  = useToast();

        const view = reactive({
            isLoaded  : false,

            idx : 0,

            info : {
                books : [
                    { idx_book:0, bookname:"", qty:0, price:"0", price_txt:"0" }
                ],
                btype             : "내부",
                idx_book          : 0,
                tutortype         : "ITM",
                idx_tutor         : 0,
                tutorname         : "",
                idx_class         : "",
                classname         : "",
                request_date      : "",
                etc_memo          : "",
            },

            goList : () => {
                router.push({ name:"ResourceManagement-TutorTextbookList" });
            },

            doDelete : () => {
                Swal.fire({
                    title : '출고현황 삭제',
                    text  : '해당 출고현황을 삭제하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            idx : view.idx
                        };
                        axios.post("/rest/resourceManagement/delRental", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.error("삭제하였습니다.");
                                view.goList();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        })
                    }
                })
            },

            doCancel : () => {
                Swal.fire({
                    title : '출고취소',
                    text  : '해당 교재의 출고를 취소하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            selected : [ view.idx ]
                        };
                        axios.post("/rest/resourceManagement/cancelTutorBookRental", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.error("취소하였습니다.");
                                view.goList();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        })
                    }
                });
            },

            doDone : () => {
                Swal.fire({
                    title : '출고완료',
                    text  : '해당 교재의 출고를 완료하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            selected : [ view.idx ]
                        };
                        axios.post("/rest/resourceManagement/doneTutorBookRental", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.error("완료하였습니다.");
                                view.doSearch();
                            } else if ( res.data.err == 200 ){
                                Swal.fire({
                                    title : '출고완료',
                                    text  : '해당 교재는 재고 부족으로 출고할 수 없습니다.'
                                });
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        })
                    }
                });
            },

            showModify : () => {
                router.push({
                    name : 'ResourceManagement-TutorTextbookMod-idx',
                    params : {
                        idx : view.idx
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/resourceManagement/getBookRentalInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;

                        view.utype_list = res.data.utype_list;

                        view.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });

        onMounted(() => {
            // Mounted
            view.idx = route.params.idx;
            if ( !view.idx ) {
                router.go(-1);
            } 

            view.doSearch();

        });

        return {view};
    }
}
</script>

<style lang="scss" scoped>
</style>